import Link from 'next/link';
import { ButtonLink, Buttons, createComponent, IconFA, If, IntrinsicProps, Loader } from 'react-commons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import style from './index.module.scss';
import { useState } from 'react';
import DailySidebarMenu from '@/components/DailySidebarMenu';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import UserStatus from '@/components/AppHeader/UserStatus';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';

interface DailyAppHeaderProps extends IntrinsicProps {
}

export default createComponent<DailyAppHeaderProps>('DailyAppHeader', { style }, function DailyAppHeader ({ className }, props) {
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(false);
  const [ authState ] = useAuthStore();

  return (
    <header className={className}>
      <div className='DailyAppHeader__Inner'>
        <div className='DailyAppHeader__ToggleSidebar' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          <IconFA icon={isSidebarOpen ? faTimes : faBars} style={{ width: '16px' }} />
        </div>
        <div className='--padMd0__r' />
        <Link href='/'>
          <a style={{ height: '34px' }}>
            <img
              src='/images/SW_Stamp_White.svg' 
              alt='Shockwave.com'
              width='44'
              height='57'
              style={{ width: 'auto', height: '34px' }}
              className='DailyAppHeader__MobileLogo'
            />
            <img
              src='/images/SW25_alt.svg' 
              alt='Shockwave.com'
              width='34'
              height='92'
              style={{ width: 'auto', height: '34px' }}
              className='DailyAppHeader__DesktopLogo'
            />
          </a>
        </Link>

        <aside>
          {
            If(!authState.ready, () => (
              <Loader />
            )).ElseIf(authState.auth, () => (
              <UserStatus />
            )).Else(() => (
              <Buttons>
                <CaptureClickEvent
                  action='upsell'
                  location='navbar'
                  href='/unlimited'
                  properties={() => ({
                    tag_name: 'upsell_navbar_btn',
                  })}
                >
                  <ButtonLink 
                    href='/unlimited' 
                    rounded medium secondary 
                    className='--hideMobile upsell_navbar_btn'
                  >
                    Subscribe
                  </ButtonLink>
                </CaptureClickEvent>
                <ButtonLink 
                  href={'/account/login'}
                  rounded medium secondary
                >
                  Log in
                </ButtonLink>
              </Buttons>
            )).EndIf()
          }
          
        </aside>
      </div>

      <DailySidebarMenu isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
    </header>
  );
});
