import Image from 'next/image';
import Link from 'next/link';
import { Button, ButtonLink, Buttons, createComponent, Flex, For, If, IntrinsicProps, Title, toClassName } from 'react-commons';

import { DailyGameColor, DailyGameFields, ExtendedGameData, FullGameData } from '@/lib/drupal/models/Games';

import style from './index.module.scss';
import { getJigsawThumbnail } from '@/components/DailyGame';
import clsx from 'clsx';
import { dailyFieldsToDate } from '@/lib/util/archiveHelpers';
import { format } from 'date-fns';

type ArchiveTileButtonAction = 'play' | 'archive' | 'gamelanding';

interface ArchiveTileButton {
  action: ArchiveTileButtonAction
  label: string
}

interface DailyGameTileProps extends IntrinsicProps {
  game: ExtendedGameData
  priority?: boolean
  isDownloadGame?: boolean
  hexColor?: string
  color?: DailyGameColor
  description?: string
  buttons?: ArchiveTileButton[]
}

function getActionHref (game: ExtendedGameData, action: ArchiveTileButtonAction) {
  
  switch(action) {
  case 'archive':
    return `${game.href}/archive`;
  case 'gamelanding':
    return `${game.href}`;
  case 'play':
    return `${game.href}&play=true`;
  }
  
}

function gameTileClassName (className: string, props: DailyGameTileProps) {
  const colorName = props.color || '';

  return className + (
    colorName 
      ? ' DailyGameTile--color DailyGameTile--color--' + colorName
      : ''
  );
}

export const DailyGameTile = 
createComponent<DailyGameTileProps>('DailyGameTile', { style }, function DailyGameTile ({ className }, props) {
  const style = props.hexColor ? { backgroundColor: props.hexColor } : {};
  return (
    <div className={gameTileClassName(className, props)}>
      <header className='DailyGameTile__header' style={style}>
        <figure>
          <Image 
            src={props.game.mobileIcon} 
            alt={props.game.title + ' icon'}
            layout='fill'
            priority={props.priority}
          />
        </figure>
        <h3>{props.game.title}</h3>
      </header>
      <section>
        <div className='p' dangerouslySetInnerHTML={{ __html: props.description || props.game.description }} />
        <Flex directionColumn className='DailyGameTile__Buttons' style={{ marginTop: 'auto' }}>
          {
            For(props.buttons, (button, i) => (
              <ButtonLink href={getActionHref(props.game, button.action)} key={i} rounded>{button.label}</ButtonLink>
            ))
          }
        </Flex>
      </section>
    </div>
  );
});

export const SimpleDailyGameTile = 
createComponent<DailyGameTileProps>('DailyGameTile', { style }, function DailyGameTile ({ className }, props) {
  const style = props.hexColor ? { backgroundColor: props.hexColor } : {};
  return (
    <div className={gameTileClassName(className, props) + ' DailyGameTile--simple'}>
      <header className='DailyGameTile__header' style={style}>
        <figure>
          <Image 
            src={props.game.mobileIcon} 
            alt={props.game.title + ' icon'}
            layout='fill'
            priority={props.priority}
          />
        </figure>
        <h3>{props.game.title}</h3>
      </header>
    </div>
  );
});

export const FeaturedDailyGameTile = 
createComponent<DailyGameTileProps>('DailyGameTile', { style }, function FeaturedDailyGameTile ({ className }, props) {
  return (
    <Link href={props.game.href}>
      <a className={gameTileClassName(className, props) + ' DailyGameTile--featured'}>
        <section>
          <figure>
            <Image 
              src={props.game.mobileIcon} 
              alt={props.game.title + ' icon'}
              layout='fill'
            />
          </figure>
          <h3>{props.game.title}</h3>
          <div className='p' dangerouslySetInnerHTML={{ __html: props.game.description }} />
        </section>
      </a>
    </Link>
  );
});

export const MinimalDailyGameTile = 
createComponent<DailyGameTileProps>('DailyGameTile', { style }, function MinimalDailyGameTile ({ className }, props) {
  return (
    <Link href={props.game.href}>
      <a className={gameTileClassName(className, props) + ' DailyGameTile--minimal'}>
        <section>
          <figure>
            <Image 
              src={props.game.mobileIcon} 
              alt={props.game.title + ' icon'}
              layout='fill'
            />
          </figure>
          <h3>{props.game.title}</h3>
          <div className='p' dangerouslySetInnerHTML={{ __html: props.game.description }} />
        </section>
      </a>
    </Link>
  );
});

interface MediaDailyGameTileProps extends DailyGameTileProps {
  featured?: boolean
  tall?: boolean
  useShortDescription?: boolean
}

export const MediaDailyGameTile = 
createComponent<MediaDailyGameTileProps>('DailyGameTile', { style }, function DailyGameTile ({ className }, props) {
  className = 
    gameTileClassName(className, props) + 
    ' DailyGameTile--media' +
    (props.featured 
      ? ' DailyGameTile--media--featured' 
      : '');

  const primaryImage = props.tall 
    ? props.game.thumbnailImgTall 
    : props.game.featuredImg;

  return (
    <div className={className}>
      <Link href={props.game.href}>
        <header className='DailyGameTile__header'>
          <figure>
            <Image 
              src={primaryImage || props.game.thumbnailImgXl || props.game.thumbnailImg} 
              alt={props.game.title + ' icon'}
              layout='fill'
              priority={props.priority}
            />
          </figure>
        </header>
      </Link>
      <section>
        <div className='p' dangerouslySetInnerHTML={{ __html: props.useShortDescription ? props.game.descriptionShort : props.game.description }} />
        <ButtonLink href={props.game.href} rounded>Play</ButtonLink>
      </section>
    </div>
  );
});

interface TaxonomyGridDailyGameTileProps extends DailyGameTileProps {
  wide?: boolean
}

export const TaxonomyGridDailyGameTile = 
createComponent<TaxonomyGridDailyGameTileProps>('DailyGameTile', { style }, function DailyGameTile ({ className }, props) {
  className = 
    gameTileClassName(className, props) + 
    ' DailyGameTile--grid';

  return (
    <Link href={props.game.href}>
      <a className={className}>
        <header className='DailyGameTile__header'>
          <figure>
            <Image 
              src={props.game.featuredImg} 
              // src={'/images/featuredPlaceholder.png'}
              alt={props.game.title + ' icon'}
              layout='fill'
              priority={props.priority}
            />
          </figure>
        </header>
        <section>
          <h4>{props.game.title}</h4>
          <div className='p' dangerouslySetInnerHTML={{ __html: props.game.descriptionShort }} />
        </section>
      </a>
    </Link>
  );
});

interface HeroDailyGameTileProps extends DailyGameTileProps {
  fullWidth?: boolean
}

export const HeroGameTile = 
createComponent<HeroDailyGameTileProps>('DailyGameTile', { style }, function HeroGameTile ({ className }, props) {
  return (
    <div className={className + ' DailyGameTile--hero' + (props.fullWidth ? ' HeroGameTile--fullWidth' : '')}>
      <section>
        <figure>
          <img 
            src={props.game.featuredImg} 
            alt={props.game.title + ' icon'}
          />
        </figure>
        <ButtonLink href={props.game.href} rounded>Play</ButtonLink>
      </section>
      <header className='DailyGameTile__header'>
        <h3>{props.game.title}</h3>
        <div className='p' dangerouslySetInnerHTML={{ __html: props.game.description }} />
      </header>
    </div>
  );
});

export const MobileHeroGameTile = 
createComponent<DailyGameTileProps>('DailyGameTile', { style }, function HeroGameTile ({ className }, props) {
  return (
    <div className={className + ' DailyGameTile--mobilehero'}>
      <header className='DailyGameTile__header'>
        <h3>{props.game.title}</h3>
        {
          If(props.onClick, () => (
            <Button onClick={props.onClick} rounded>Play</Button>
          )).Else(() => (
            <ButtonLink href={props.game.href} rounded>Play</ButtonLink>
          )).EndIf()
        }
      </header>
      <section>
        <figure>
          <img 
            src={props.game.thumbnailImgXl || props.game.thumbnailImg} 
            alt={props.game.title + ' icon'}
            // priority={props.priority}
          />
        </figure>
      </section>
    </div>
  );
});

interface WideDailyGameTileProps extends DailyGameTileProps {
  hero?: boolean
}

export const WideDailyGameTile = 
createComponent<WideDailyGameTileProps>('DailyGameTile', { style }, function DailyGameTile ({ className }, props) {
  className = `${gameTileClassName(className, props)} DailyGameTile--wide`;
  if (props.hero) className += ' DailyGameTile--wide--hero';

  return (
    <Link href={props.game.href}>
      <a className={className}>
        <figure>
          <Image 
            src={props.game.featuredImg} 
            alt={props.game.title + ' icon'}
            layout='fill'
            priority={props.priority}
          />
        </figure>
        <section>
          <p className='DailyGameTile__Title'>{props.game.title}</p>
          <div className='p' dangerouslySetInnerHTML={{ __html: props.hero 
            ? props.game.description
            : props.game.descriptionShort }} />
        </section>
      </a>
    </Link>
  );
});

export const MediaDetailsDailyGameTile = 
createComponent<MediaDailyGameTileProps>('DailyGameTile', { style }, function DailyGameTile ({ className }, props) {
  className = 
    gameTileClassName(className, props) + 
    ' DailyGameTile--mediaDetails' +
    (props.featured 
      ? ' DailyGameTile--mediaDetails--featured' 
      : '');

  return (
    <Link href={props.game.href}>
      <a className={className}>
        <header className='DailyGameTile__header'>
          <figure>
            <Image 
              src={props.game.featuredImg} 
              alt={props.game.title + ' icon'}
              layout='fill'
              priority={props.priority}
            />
          </figure>
        </header>
        <section>
          <p>{props.game.title}</p>
          <div className='p' dangerouslySetInnerHTML={{ __html: props.game.description }} />
        </section>
      </a>
    </Link>
  );
});
    
interface HeroPlayGameTileProps extends DailyGameTileProps {
  today: Date
  isBonusGame: boolean
  game: FullGameData
  isFullWidth?: boolean
}

export const HeroPlayGameTile = 
createComponent<HeroPlayGameTileProps>('DailyGameTile', { style }, function HeroPlayGameTile ({ mergeClassNames }, props) {
  const todayFormatted = props.today.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const imgSrc = props.game.pageLayout === 'jigsaw' 
    ? getJigsawThumbnail(
      props.today, 0, 
      props.isBonusGame ? 'bonus' : 'daily'
    )
    : props.game.featuredImg;

  // const className = mergeClassNames(
  //   toClassName('DailyGameTile--playhero', { 
  //     [ 'isFullWidth' ]: props.isFullWidth,
  //     [ 'isJigsaw' ]: props.game.pageLayout === 'jigsaw',
  //   })
  // );

  const className = clsx(
    'DailyGameTile',
    'DailyGameTile--hero',
    'DailyGameTile--playhero',
    {
      'DailyGameTile--playhero--isFullWidth': props.isFullWidth,
      'DailyGameTile--playhero--isJigsaw': props.game.pageLayout === 'jigsaw',
    }
  );

  return (
    <div className={className}>
      <header className='DailyGameTile__header'>
        <h3>{props.game.title}</h3>
        <p><strong>{todayFormatted}</strong></p>
        <div className='p' dangerouslySetInnerHTML={{ __html: props.game.shortDescription }} />
        <Button onClick={props.onClick} rounded>Play</Button>
      </header>
      <section>
        <figure>
          <img 
            src={imgSrc} 
            alt={props.game.title + ' icon'}
          />
        </figure>
      </section>
    </div>
  );
});

interface BonusDailyGameTileProps extends DailyGameTileProps {
  archiveDate: DailyGameFields
}

export const BonusDailyGameTile =
createComponent<BonusDailyGameTileProps>('DailyGameTile', { style }, function BonusDailyGameTile ({ className }, props) {

  const style = props.hexColor ? { backgroundColor: props.hexColor } : {};

  className = clsx(
    'DailyGameTile',
    'DailyGameTile--bonus'
  );

  return (
    <div className={gameTileClassName(className, props)} style={style}>
      <header className='DailyGameTile__header'>
        <figure>
          <Image 
            src={props.game.mobileIcon} 
            alt={props.game.title + ' icon'}
            layout='fill'
            priority={props.priority}
          />
        </figure>
        <h3>{props.game.title}</h3>
      </header>
      <section>
        <p className='DailyGameTile__ArchiveDate'>{format(dailyFieldsToDate(props.archiveDate), 'MMM do yyyy')}</p>
        <Flex directionColumn className='DailyGameTile__Buttons' style={{ marginTop: 'auto' }}>
          <ButtonLink href={getActionHref(props.game, 'play')} key={props.game.uid} rounded>Play</ButtonLink>
        </Flex>
      </section>
    </div>
  );

});