import { ButtonLink, createComponent, IntrinsicProps } from 'react-commons';

import { CaptureClickEvent } from '@/lib/util/captureClickEvent';
import { useAnnualPlanPromo } from '@/lib/util/useAnnualPlanPromo';
import style from './index.module.scss';

interface PromoTileProps extends IntrinsicProps {
  eventAction: string
  eventTagName: string
  eventLocation?: string
}

export default
createComponent<PromoTileProps>('PromoTile', { style }, function PromoTile ({ className }, props) {
  const { promoDescription } = useAnnualPlanPromo();

  return (
    <div className={className}>
      <figure>
        <img 
          src={'/images/saleCtaIconsMobile.svg'} 
          alt={'Icons'}
          aria-hidden
        />
        <img 
          src={'/images/saleCtaIcons.svg'} 
          alt={'Icons'}
          aria-hidden
        />
      </figure>
      <section>
        <h3 dangerouslySetInnerHTML={{ __html: promoDescription || "There's years worth of content to play" }} />
        <p>With a subscription you can gain access to the entire catalog of daily game archives. Ready to play whenever you are!</p>
        <CaptureClickEvent
          action={props.eventAction}
          location={props.eventLocation || ''}
          properties={() => ({
            tag_name: props.eventTagName,
          })}
          href='/unlimited'
        >
          <ButtonLink
            className={props.eventTagName}
            href='/unlimited' 
            rounded
          >
            Learn More
          </ButtonLink>
        </CaptureClickEvent>
      </section>
    </div>
  );
});
