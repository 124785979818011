import '@/styles/app.scss';
import '@/styles/themes.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
// import '@/styles/dev.scss';

import pkg from '@/package.json';

import { useEffect } from 'react';
import { Modals, Notifications, setConfig, ComScore } from 'react-commons';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { AuthProvider, useAuthStore } from '@/lib/drupal/stores/auth';
import { AppStoreProvider } from '@/lib/stores/app';
import { useDrupalAuth } from '@/lib/drupal/hooks/useDrupalAuth';
import { classicOnlyComponents, classicOnlyRoutes, modernOnlyComponents, modernOnlyRoutes, ThemeStoreProvider, useThemeStore } from '@/lib/stores/theme';
import { RecentlyPlayedProvider } from '@/lib/stores/recentlyPlayed';

import BackgroundImage from '@/components/BackgroundImage';
import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';
import LoadingPanel from '@/components/LoadingPanel';
import { Playwire } from '@/components/playwire/Playwire';
import { HistoryProvider } from '@/lib/stores/history';
import App from '@/components/App';

setConfig({
  APP_DESCRIPTION: process.env.APP_CONFIG.DESCRIPTION,
  APP_KEYWORDS: process.env.APP_CONFIG.KEYWORDS,
  APP_SEARCH_URL: process.env.APP_CONFIG.SEARCH_URL,
  APP_THEME_COLOR: process.env.APP_CONFIG.THEME,
  APP_TITLE: process.env.APP_CONFIG.APP_TITLE,
  APP_TITLE_TEMPLATE: process.env.APP_CONFIG.TITLE_TEMPLATE,
  BASE_URL: process.env.APP_CONFIG.URL,
  COMSCORE_CLIENT_ID: process.env.APP_CONFIG.COMSCORE_CLIENT_ID,
  IDENTIFIER: process.env.IDENTIFIER,
  NODE_ENV: process.env.NODE_ENV,
  PACKAGE_NAME: pkg.name,
  REPORT_LOG_ON_LEVELS: 'error,fatal'
});

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    autocapture: false,
    capture_pageview: false,
    capture_pageleave: true,
    // session_idle_timeout_seconds: 5400,
    loaded: (posthog) => {
      if (
        process.env.NODE_ENV === 'development' || 
        process.env.NEXT_PUBLIC_HOST_ENV === 'staging'
      ) {
        posthog.debug();
      }
    },
  });
}
function AppInner ({ Component, pageProps }) {
  const [ themeState ] = useThemeStore();
  const [ authState ] = useAuthStore();
  const router = useRouter();

  const determineLayoutTheme = () => {
    const parser = new UAParser(window.navigator.userAgent);
    const isMobile = parser.getResult().device.type === 'mobile';

    if (isMobile) {
      return 'modern';
    }

    if (modernOnlyRoutes.includes(router.pathname) || modernOnlyComponents.includes(Component.displayName)) {
      return 'modern';
    }

    if (classicOnlyRoutes.includes(router.pathname) || classicOnlyComponents.includes(Component.displayName)) {
      return 'default';
    }

    const userLayoutPreference = authState.user?.layoutPreference;
    const cookieLayoutPreference = Cookies.get('sw_result_layout');

    return userLayoutPreference || cookieLayoutPreference || 'modern';
  };

  const applyTheme = (layout) => {
    const validThemes = layout === 'classic' 
      ? [ 'default', 'contrast' ] 
      : [ 'modern', 'modernDark' ];

    document.body.dataset.theme = validThemes.includes(themeState.theme)
      ? themeState.theme
      : validThemes[ 0 ];
  };

  useEffect(() => {
    const layout = determineLayoutTheme();
    applyTheme(layout);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState, router.pathname, themeState, Component.displayName ]);

  return (
    <App
      theme={themeState.theme}
      authHook={useDrupalAuth}
      googleAnalyticsOptions={{
        uakey: process.env.APP_CONFIG.GOOGLE_UA_ID,
        ga4key: process.env.APP_CONFIG.GOOGLE_GA4_ID,
      }}
    >
      <ComScore clientId={process.env.APP_CONFIG.COMSCORE_CLIENT_ID} />
      <Playwire 
        PUB_ID={process.env.NEXT_PUBLIC_PLAYWIRE_PUB_ID} 
        WEBSITE_ID={process.env.NEXT_PUBLIC_PLAYWIRE_WEBSITE_ID} 
      />
      <Modals />
      <Notifications />
      <LoadingPanel />
      <Component {...pageProps} />
    </App>
  );
}

function _app ({ Component, pageProps }) {
  const router = useRouter();

  // Track subsequent page views
  useEffect(() => {
    // Track page views
    const onRouteChangeComplete = () => {
      posthog.capture('$pageview', {
        layout: Cookies.get('sw_result_layout'),
      });
    };
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <App.Provider
      authProvider={AuthProvider}
    >
      <PostHogProvider client={posthog}>
        <AppStoreProvider>
          <ThemeStoreProvider>
            <RecentlyPlayedProvider>
              <HistoryProvider>
                <BackgroundImage
                  componentName={Component.displayName}
                />
                <AppInner
                  Component={Component}
                  pageProps={pageProps}
                />
              </HistoryProvider>
            </RecentlyPlayedProvider>
          </ThemeStoreProvider>
        </AppStoreProvider>
      </PostHogProvider>
    </App.Provider>
  );
}

export default _app;
